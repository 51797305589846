import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography
} from "@mui/material";

const CancelBookingDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Foglalás törlése</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Biztosan törölni szeretnéd ezt a foglalást?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Mégse
        </Button>
        <Button variant="contained" onClick={onConfirm} color="error" fontWeight="bold">
          <Typography textTransform="none">Törlés</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelBookingDialog;