import { useState, useEffect } from 'react';
import {
  Stack,
  Divider,
} from "@mui/material";
import useGetUser from "./useGetUser";
import useCancelBooking from '../../api/useCancelBooking';
import Page from '../../components/Page';
import Loading from '../../components/Loading';
import Article from '../../components/Article';
import BorderedBox from '../../components/BorderedBox';
import BookingItem from './BookingItem';
import { StorageKeys } from '../../services/constants/StorageKeys';
import { storage } from "../../services/storageService";
import { useSnackbar } from "../../contexts/SnackBarContext";
import CancelBookingDialog from './CancelBookingDialog';

const ProfilePage = () => {
  const { getUser, data: getUserData, loading: getUserLoading, error: getUserError } = useGetUser();
  const { cancelBooking, loading: cancelBookingLoading, error: cancelBookingError } = useCancelBooking();
  const showSnackbar = useSnackbar();
  const [isDialogOpen, setOpen] = useState(false);

  const bookingToBeCancelled = storage.get(StorageKeys.BOOKING_TO_BE_CANCELLED);

  useEffect(() => {
    if (getUserError) {
      showSnackbar(getUserError?.message);
      return;
    }

    if (cancelBookingError) {
      showSnackbar(cancelBookingError?.message);
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserError, cancelBookingError]);
  

  const handleOpenDialog = (bookingId) => {
    storage.set(StorageKeys.BOOKING_TO_BE_CANCELLED, bookingId);
    setOpen(true);
  };

  const rejectBookingCancelation = () => {
    storage.remove(StorageKeys.BOOKING_TO_BE_CANCELLED);
    setOpen(false);
  };

  const handleConfirmCancel = async () => {
    setOpen(false);

    const success = await cancelBooking(bookingToBeCancelled);
    if (success) {
      showSnackbar("Sikeresen lemondtad a foglalást", "success");
      getUser();
    }
  };

  return (
    <Page>
      {getUserLoading && <Loading />}

      {!getUserLoading && getUserData && (
        <Stack 
          direction={{ xs: "column",  md: "row" }} 
          spacing={{ xs: 3, sm: 4, md: 5 }} alignItems={{ xs: "center", md: "stretch" }}>
          {getUserData.bookings.length > 0 && 
            <Article 
              title="Foglalások" 
              xsHorizontalPadding={3} 
              width={{ xs: "80vw", sm: "60vw", md: "40vw", lg: "30vw", xl: "25vw" }}>

              <Stack divider={<Divider orientation="horizontal" flexItem/>} spacing={2} alignItems="center">
                {getUserData.bookings.map((booking) => (
                  <BookingItem 
                    booking={booking} 
                    onDelete={handleOpenDialog} 
                    loading={cancelBookingLoading && bookingToBeCancelled === booking?.id }
                  />
                ))}
              </Stack>
            </Article>
          }
          
          <Article
            title="Profil" 
            xsHorizontalPadding={3}
            width={{ xs: "80vw", sm: "60vw", md: "30vw", lg: "20vw", xl: "20vw" }}>

            <Stack spacing={3}>
              <BorderedBox title="Név" lable={getUserData.name} />
              <BorderedBox title="Email" lable={getUserData.email} />
              <BorderedBox title="Telefonszám" lable={getUserData.phoneNumber} />
            </Stack>
          </ Article>
        </Stack>
      )}

      <CancelBookingDialog
        open={isDialogOpen} 
        onClose={rejectBookingCancelation} 
        onConfirm={handleConfirmCancel} 
      />
    </Page>
  );
};



export default ProfilePage;
