import Header from './Header';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import { Box, Stack } from "@mui/material";
import CustomSnackbar from '../components/CustomSnackbar';
import isEmbeddedBrowser from '../utils/embeddedBrowserChecker';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { useTenant } from '../contexts/TenantContext';
import Loading from '../components/Loading';

export default function RootLayout() {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [headerKey, setHeaderKey] = useState(0);
  const { tenant, loading, error } = useTenant(); 
  const theme = useTheme();

  const forceHeaderReRender = () => {
    setHeaderKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    setIsSnackbarOpen(isEmbeddedBrowser());
  }, []);

  if (loading) {
    return (
      <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Loading size={124}/>
      </Box>
    ) 
  }

  if (error) {
    return (
      <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {error}
      </Box>
    ) 
  }

  if (tenant) {
    return (
      <Stack sx={{ background: theme.background }}>
        <CustomSnackbar 
          open={isSnackbarOpen} 
          setOpen={setIsSnackbarOpen}
          message="Ha messenger-ből vagy hasonló app-ból nyitod meg az alkalmazást előfordulhat, hogy nem minden funkció működik megfelelően. Másold át a linket egy böngészőbe!"
          severity="warning"
          mt={7} 
        />
        <Header key={headerKey} />
        <Box minHeight="100vh">
          <Outlet  sx={{ minHeight: "100vh" }} context={{ forceHeaderReRender }}/>
        </Box>
        <Footer />
      </Stack>
    )
  }
  
}
