import { Typography, Stack, Box, IconButton, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import CustomModal from './CustomModal';
import dayjs from 'dayjs';
import { getDisplayDate } from '../../../utils/dateUtils';
import DeleteIcon from '@mui/icons-material/Delete';
import useGetBooking from '../api/useGetBooking';
import { alpha } from "@mui/material/styles";
import SelectedSupply from '../../../components/SelectedSupply';
import { useSnackbar } from '../../../contexts/SnackBarContext';
import useCancelBooking from '../../../api/useCancelBooking';

const BookingDetailsModal = (props) => {
  const { show, handleClose, bookingId, reloadSignal = () => null } = props;
  const { getBooking, data: bookingData } = useGetBooking();
  const { cancelBooking } = useCancelBooking();
  const theme = useTheme();
  const showSnackbar = useSnackbar();

  const supply = bookingData?.supply;

  useEffect(() => {
    if (bookingId && bookingId !== "") {
      getBooking(bookingId);
    }
  }, [bookingId]);

  const deleteBooking = async () => {
    if (bookingId && bookingId !== "") {

      const success = await cancelBooking(bookingId);

      if (success) {
        showSnackbar("Sikeres törlés", "success");
        reloadSignal();
        handleClose();
      }
    }
  };

  return (
    <CustomModal
      open={show}
      onClose={handleClose}
      title="Foglalás részletei">
      <Stack spacing={2}>
        { supply&& <SelectedSupply supply={supply}/>}

        <Box>
          <Typography>Vendég: {bookingData?.name}</Typography>
          {bookingData?.phoneNumber && <Typography>Telefonszám: {bookingData.phoneNumber}</Typography> }
          <Typography>Időpont: {getDisplayDate(dayjs(bookingData?.date))} {bookingData?.startTime} {bookingData && <>-</>} {bookingData?.endTime} </Typography>
        </Box>

        <Box alignSelf="center">
          <IconButton
            color="error"
            onClick={deleteBooking}
            sx={{
              color: theme.palette.error.main,
              '&:hover': {
                backgroundColor: alpha(theme.palette.error.light, 0.0),
              },
              display: 'flex',
              alignItems: 'center', 
            }}
          >
            <Typography variant="body2">Foglalás törlése</Typography>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Stack>
    </CustomModal>
  );
};

export default BookingDetailsModal;
