import Page from "../../../components/Page";
import { StorageKeys } from "../../../services/constants/StorageKeys";
import { storage } from "../../../services/storageService";
import usePreReserve from '../api/usePreReserve';
import { Routes } from "../../../routes/RouteConstants"
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import Loading from '../../../components/Loading';
import Article from "../../../components/Article";

const PreReserveLayout = () => {
  const { preReserve, loading} = usePreReserve();
  const navigate = useNavigate();

  useEffect(() => {
    preReservAppointment();
  }, [])

  const preReservAppointment = async () => {
    const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
    const selectedAppointment = storage.get(StorageKeys.SELECTED_APPOINTMENT);
    if (!selectedSupply || !selectedAppointment) {
      console.error("Selected supply or appointment is missing");
      return;
    }
  
    const preReserveRequest = {
      supplyId: selectedSupply.id,
      date: selectedAppointment.date,
      startTime: selectedAppointment.time,
    };
  
    try {
      const preReservedBookingId = await preReserve(preReserveRequest);
      if (!preReservedBookingId) {
        console.error("Error during processing pre-reserve");
        return;
      }
  
      storage.set(StorageKeys.PRE_RESERVED_BOOKING_ID, preReservedBookingId);
      storage.set(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT, Routes.CONFIRM_BOOKING);
      navigate(Routes.CONFIRM_BOOKING);
    } catch (error) {
      console.error("Error in pre-reserving the appointment:", error);
    }
  };

  return (      
    <Page>
      <Article title="Előfoglalás" minWidth="common">
        { loading && <Loading />}
      </Article>
    </Page>
  );
};

export default PreReserveLayout;
