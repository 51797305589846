import React from 'react';
import { RouterProvider } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import router from './routes/AppRouter';
import { SnackbarProvider } from './contexts/SnackBarContext';
import { TenantProvider } from './contexts/TenantContext';
import DynamicThemeProvider from './providers/DynamicThemeProvider';

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackbarProvider>
        <TenantProvider>
          <DynamicThemeProvider>
            <RouterProvider router={router} />
          </DynamicThemeProvider>
        </TenantProvider>
      </SnackbarProvider>
    </LocalizationProvider>
  );
}

export default App;
