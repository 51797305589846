const readTenantMappings = () => {
  const tenantMappingsJson = process.env.REACT_APP_TENANT_MAPPINGS;

  if (!tenantMappingsJson) {
    console.error('REACT_APP_TENANT_MAPPINGS environment variable is missing.');
    return;
  }

  try {
    return JSON.parse(tenantMappingsJson);
  } catch (error) {
    console.error('Error parsing tenant mappings from env:', error);
  }
};

const TENANT_MAPPINGS = readTenantMappings();

export default TENANT_MAPPINGS;