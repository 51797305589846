import { Stack, Box, Typography, IconButton, useTheme } from "@mui/material";
import SelectedSupply from "../../components/SelectedSupply";
import { alpha } from "@mui/material/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from "../../components/Loading";
import dayjs from "dayjs";
import 'dayjs/locale/hu';

const BookingItem = ({ booking, onDelete, loading }) => {
  const theme = useTheme();
  dayjs.locale('hu');

  return (
    <>
      {loading ? <Loading size={40} mt={3} /> :
        <Stack key={booking?.id} spacing={1}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SelectedSupply fixedWidth="170px" supply={booking?.supply} />
            <Box>
              <Typography fontSize={14} fontWeight="bold" gutterBottom>{booking?.date.replaceAll("-", ".")}</Typography>
              <Typography fontSize={14} textTransform="capitalize" gutterBottom>{dayjs(booking?.date).format("dddd")}</Typography>
              <Typography variant="body2" align="center" noWrap>{`${booking?.startTime} - ${booking?.endTime}`}</Typography> 
            </Box>
          </Stack>
        
          <IconButton
            color="error"
            onClick={() => onDelete(booking.id)}
            sx={{
              color: theme.palette.error.main,
              '&:hover': {
                backgroundColor: alpha(theme.palette.error.light, 0.0),
              },
              display: 'flex',
              alignItems: 'center', 
            }}
          >
            <Typography variant="body2">Foglalás lemondás</Typography>
            <DeleteIcon />
          </IconButton>
        </Stack>
      }
    </>
    
  )
}

export default BookingItem;
