import { Stack, Typography } from "@mui/material";
import useGetSupplies from "./useGetSupplies";
import Page from "../../components/Page";
import Loading from "../../components/Loading";
import SupplyItem from "./SupplyItem";

const SupplyPage = () => {
  const { data, loading } = useGetSupplies();

  return (
    <Page>
      {loading && <Loading />}
      
      {!loading && data && (
        <>
          {data.length > 0 ? (
            <Stack spacing={3} width="100%">
              {data.map((supply) => (
                <SupplyItem key={supply.id} supply={supply} />
              ))}
            </Stack>
          ) : (
            <Typography variant="h6" component="h1" mt={3} fontWeight="bold" color="primary">
              Nincsenek elérhető szolgáltatások.
            </Typography>
          )}
        </>
      )}
    </Page>
  );
};

export default SupplyPage;
