import { createTheme, ThemeProvider } from '@mui/material/styles';
import { huHU as datePickerHu } from '@mui/x-date-pickers/locales';
import { huHU as coreHu } from '@mui/material/locale';
import { useTenant } from '../contexts/TenantContext';

const DynamicThemeProvider = ({ children }) => {
  const { tenant } = useTenant();

  const background = tenant?.displayConfig?.background;

  const color = tenant?.displayConfig?.color;
  const headerBackground = color?.headerBackground || "#000";
  const footerBackground = color?.footerBackground || "#fff";
  const primary = color?.primaryColor || "#d3d3d3"; //#95570B 8750f4
  const primarLight = color?.primaryColorLight || "#d3d3d3"; //#C9A983 8750f4
  const secondary = color?.secondaryColor || "#000";
  const secondaryLight = color?.secondaryColorLight || "#323232"; //#323232

  const theme = createTheme(
    {
      background: background,
      palette: {
        headerBackground: headerBackground,
        footerBackground: footerBackground,
        primary: {
          main: primary,
          light: primarLight,
        },
        secondary: {
          main: secondary,
          light: secondaryLight,
        },
      },
    },
    datePickerHu,
    coreHu
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default DynamicThemeProvider;
