import { useState } from 'react';
import apiClient from '../../../services/apiClientService';
import { Routes } from '../../../routes/RouteConstants';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { useTenant } from '../../../contexts/TenantContext';

const useConfirmBooking = () => {
  const { getTenant } = useTenant();
  const [loading, setLoading] = useState(false);
  const { handleError } = useErrorHandler();

  const confirmBooking = async (bookingId) => {
    setLoading(true);

    try {
      const tenant = await getTenant();
      const url = `/v1/tenants/${tenant.tenantId}/bookings/${bookingId}/confirm`;
      await apiClient.put(url);
      return true;
    } catch (error) {
      handleError(error, Routes.CONFIRM_BOOKING);   
    } finally {
      setLoading(false);
    }
  };

  return { confirmBooking, loading };
};

export default useConfirmBooking;
