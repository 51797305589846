import { 
  Card,
  Typography,
  Stack,
  Box
} from "@mui/material";
import { Routes } from "../../routes/RouteConstants";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { storage } from "../../services/storageService";
import { StorageKeys } from "../../services/constants/StorageKeys";

const SupplyItem = ({ supply }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    storage.set(StorageKeys.SELECTED_SUPPLY, supply);
    navigate(Routes.BOOKING);
  }

  return (
    <Card 
      sx={{ 
        width: "100%", 
        mb: 2, 
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", 
        borderRadius: 2, 
        '&:hover': {
          boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)"
        }
      }}
    >
      <Stack 
        direction={{ xs: 'column', sm: 'row' }} 
        justifyContent="space-between" 
        alignItems="center" 
        p={2} 
        spacing={1}
      >
        <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
          <Typography variant="h6" fontWeight="bold" textAlign={{ xs: "center", sm: "left"}} noWrap>
            {supply?.name}
          </Typography>
        </Box>

        <Box>
          <Typography variant="body1" color="text.primary" noWrap>
            {supply?.price} Ft
          </Typography>
        </Box>

        <Box>
          <Typography variant="body1" color="text.primary" noWrap >
            {supply?.duration} perc
          </Typography>
        </Box>

        <Box>
          <CustomButton text="Foglalás" onClick={handleClick}/>
        </Box>
      </Stack>
    </Card>
  );
}

export default SupplyItem;
