import { createContext, useContext, useState } from 'react';
import CustomSnackbar from '../components/CustomSnackbar';

const SnackbarContext = createContext();

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const showSnackbar = (message, severity = "error") => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      <CustomSnackbar
        open={snackbarOpen}
        setOpen={handleClose}
        message={snackbarMessage}
        severity={severity}
      />
      {children}
    </SnackbarContext.Provider>
  );
};
