import { useState } from 'react';
import apiClient from '../services/apiClientService';
const useGetTenant = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const getTenant = async (tenantId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.get("/v1/public/tenants/" + tenantId);
      return response.data;
    } catch (error) {
      console.log(error.code)
      if (error?.code === "ERR_NETWORK") {
        setError("A szerver nem érhető el.");
      } else {
        setError(error?.response?.data?.message);      
      }
    } finally {
      setLoading(false);
    }
  };

  return { getTenant, loading, error };
};


export default useGetTenant;
