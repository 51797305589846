import { useEffect, useState } from 'react';
import apiClient from '../../services/apiClientService';
import { Routes } from '../../routes/RouteConstants';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useTenant } from '../../contexts/TenantContext';

const useGetUser = () => {
  const { getTenant } = useTenant();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { handleError } = useErrorHandler();
  
  const getUser = async () => {
    setLoading(true);

    try {
      const tenant = await getTenant();
      const url = `/v1/tenants/${tenant.tenantId}/users/me`;
      const response = await apiClient.get(url);
      setData(response.data);
    } catch (error) {
      handleError(error, Routes.PROFILE);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { getUser, data, loading };
};

export default useGetUser;
