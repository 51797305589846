import { useEffect, useState } from 'react';
import apiClient from '../services/apiClientService';
import { Routes } from '../routes/RouteConstants';
import useErrorHandler from '../hooks/useErrorHandler';
import dayjs from 'dayjs';
import { getFormattedDate } from '../utils/dateUtils';
import { useTenant } from '../contexts/TenantContext';

const useGetDailySchedules = (includeBookings, supplyId) => {
  const { getTenant } = useTenant();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { handleError } = useErrorHandler();

  const getDailySchedules = async (startDate, includeBookings, supplyId) => {
    setLoading(true);

    try {
      const tenant = await getTenant();
      const url = composeUrl(tenant.tenantId, startDate, includeBookings, supplyId);
      const response = await apiClient.get(url);
      setData(response.data);
    } catch (error) {
      handleError(error, Routes.DASHBOARD);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDailySchedules(dayjs(), includeBookings, supplyId); // default startDate is today
  }, []);

  return { data, loading, getDailySchedules };
};

const composeUrl = (tenantId, startDate, includeBookings, supplyId) => {
  let url = `/v1/public/tenants/${tenantId}/daily-schedules?`;

  if (startDate) {
    url += `startDate=${getFormattedDate(startDate)}&`;
  }

  if (includeBookings) {
    url += `includeBookings=${includeBookings}&`;
  }

  if (supplyId) {
    url += `supplyId=${supplyId}`;
  }
  
  return url;
}


export default useGetDailySchedules;
