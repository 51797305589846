import { useSnackbar } from '../contexts/SnackBarContext';
import { useNavigate } from 'react-router-dom';
import { StorageKeys } from "../services/constants/StorageKeys";
import { storage } from "../services/storageService";
import { Routes } from '../routes/RouteConstants';

const useErrorHandler = () => {
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();

  const handleError = (error, targetRouteBeforeRedirect) => {
    if (isNetworkError(error)) {
      showSnackbar("Sajnos a szerver jelenleg nem elérhető. Foglalj időpontot telefonon keresztül. 06 30 452 8948");
      return;
    }  

    // Redirect needed
    if (targetRouteBeforeRedirect) {
      if (isUnauthorizedError(error)) {
        handleUnauthorized(navigate, targetRouteBeforeRedirect);
        return;
      } 

      if (isUserNotVerifiedError(error)) {
        handleUserNotVerified(navigate, targetRouteBeforeRedirect);
        return;
      }
    }

    const publicMessage = error?.response?.data?.message;
    if (publicMessage) {
      showSnackbar(publicMessage);
      return;
    }  

    showSnackbar("Ismeretlen hiba történt, próbáld meg később vagy foglalj időpontot telefonon keresztül.  06 30 452 8948 (Error code: " + error.code + ")");
  };

  return { handleError };
};

const isNetworkError = (error) => {
  return error.code === "ERR_NETWORK";
};

const isUnauthorizedError = (error) => {
  return error?.response?.status === 401;
};

const handleUnauthorized = (navigate, targetRouteBeforeRedirect) => {
  console.log("Request is unauthenticated, redirect needed to login route");

  storage.set(StorageKeys.IS_USER_AUTHENTICATED, false);
  storage.set(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT, targetRouteBeforeRedirect);

  navigate(Routes.LOGIN);
};

const isUserNotVerifiedError = (error) => {
  return error?.response?.data?.code === "DEC-0006";
};

const handleUserNotVerified = (navigate, targetRouteBeforeRedirect) => {
  console.log("User is not verified, redirect needed to complete-registration route");

  storage.set(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT, targetRouteBeforeRedirect);

  navigate(Routes.COMPLETE_REGISTRATION);
};

export default useErrorHandler;
