import { useState } from 'react';
import apiClient from '../../services/apiClientService';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useTenant } from '../../contexts/TenantContext';

const useInitiateSocialLogin = () => {
  const { getTenant } = useTenant();
  const [loading, setLoading] = useState(false);
  const { handleError } = useErrorHandler();
  
  const initiateSocialLogin = async () => {
    setLoading(true);
    try {
      const tenant = await getTenant();
      const url = `/v1/public/tenants/${tenant.tenantId}/auth/initiate-social-login`;
      const response = await apiClient.get(url);
      return response?.data?.redirectUrl;
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return { initiateSocialLogin, loading };
};

export default useInitiateSocialLogin;
