import { Box, Card, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Page from '../../components/Page';
import ManualBookingModal from './modals/ManualBookingModal';
import BlockTimeModal from './modals/BlockTimeModal';
import useGetSupplies from '../supply/useGetSupplies';
import useGetDailySchedules from '../../api/useGetDailySchedules';
import dayjs from 'dayjs';
import CustomButton from '../../components/CustomButton';
import BookingDetailsModal from './modals/BookingDetailsModal';
import Loading from '../../components/Loading';

export default function DashboardLayout() {
    const { data: suppliesData } = useGetSupplies();
    const { data: newDailySchedules, loading: dailySchedulesLoading, getDailySchedules } = useGetDailySchedules(true);
    const [fetchedDailySchedules, setFetchedDailySchedules] = useState([]);
    const [supplyId, setsupplyId] = useState("");
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [showBlockTimeModal, setShowBlockTimeModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [bookingId, setBookingId] = useState("");
    const [reRenderTrigger, setReRenderTrigger] = useState(false);

    useEffect(() => {
      if (newDailySchedules.length > 0) {
        setFetchedDailySchedules((prevData) => [...prevData, ...newDailySchedules]);
      }
    }, [newDailySchedules]); 

    useEffect(() => {
      if (supplyId) {
        setFetchedDailySchedules([]);
        getDailySchedules(dayjs(), true, supplyId);
      }
    }, [supplyId, reRenderTrigger]);

    const loadMoreSchedules = () => {
      const nextDay = dayjs(newDailySchedules[newDailySchedules.length - 1].date).add(1, 'day');
      getDailySchedules(nextDay, true, supplyId);
    }

    const renderTimeSlot = (date, time) => {
      if (!time || !date) {
        return <>  </>;
      }

      if (time.available !== true) {
        return (
          <CustomButton
            key={`${date}-${time.startTime}`}
            variant="outlined"
            color="primary"
            onClick={() => openBookedModal(time.id)}
            fullwidth={true}
            text={time?.guest + "<br />" + time?.startTime + " - " + time?.endTime} />
        );
      }

      return (
        <CustomButton
          key={`${date}-${time.startTime}`}
          variant="outlined"
          color="secondary"
          onClick={() => openAppointmentModal(date, time)}
          fullwidth={true}
          text={time.startTime} />
      );
    }

    const openAppointmentModal = (date, time) => {
      setModalData(() => {
        setShowAppointmentModal(true);
        return { date, time, supply: suppliesData.find(supply => supply.id === supplyId) };
      });
    }

    const openBlockTimeModal = () => {
        setShowBlockTimeModal(true);
    }

    const openBookedModal = (bookingId) => {
      setBookingId(_ => {
          setShowBookingModal(true);
          return bookingId;
      })
    }

    const reRender = () => {
      setReRenderTrigger((prev) => !prev)
    }
  
    const renderAppointments = () => {
      if (!fetchedDailySchedules) {
        return <></>;
      }

      return (
        fetchedDailySchedules.map(schedule => {
          const date = schedule.date;
          return (
            <Grid key={date} item xs={6} sm={6} md={4} lg={2}>
              <Card>
                <Stack direction={{ xs: "column", xl: "row" }} justifyContent="space-around" alignItems="center" p={1} bgcolor="black">
                  <Typography variant="h6" textTransform="capitalize" color="white" noWrap>
                    {schedule.weekday}
                  </Typography>

                  <Typography variant="body1" color="grey.200" noWrap fontSize={{ xs: 12, sm: 14, md: 16 }}>
                    {schedule.formattedDate}
                  </Typography>
                </Stack>

                <Box>
                  <Stack p={1} spacing={1}>
                      {schedule.timeSlots.map(time => (renderTimeSlot(date, time)))}
                  </Stack>
                </Box>
              </Card>
            </Grid>
          )
      }));
    }

    return (
      <Page>
        <Stack spacing={2} alignItems="center" width="100%">
          <Box width="300px">
            <TextField select={true} id="supply-select" label="Szolgáltatás"
              value={supplyId} variant="outlined" margin="normal" fullWidth={true}
              onChange={(event) => setsupplyId(event.target.value)}>
              {suppliesData?.map(supply => <MenuItem key={supply.id} value={supply.id}>{supply.name}</MenuItem>)}
            </TextField>
          </Box>

          <Box width="300px">
            <CustomButton color="primary" text="Inaktív időszak beállítás" onClick={openBlockTimeModal} fullwidth={true} />
          </Box>

          {dailySchedulesLoading &&  
            <Box height="50vh" display="flex" flexDirection="column" justifyContent="center"> 
              <Loading /> 
            </Box>
          }

          {!dailySchedulesLoading && 
            <Grid container spacing={1}>
              {renderAppointments()}
            </Grid>
          }

          <Box width="300px">
            <CustomButton variant="outlined" color="primary" text="Tovább" onClick={loadMoreSchedules} fullwidth={true} />
          </Box>
        </Stack>

        <ManualBookingModal show={showAppointmentModal} modalData={modalData} handleClose={() => setShowAppointmentModal(false)} reloadSignal={reRender} />

        <BlockTimeModal show={showBlockTimeModal} handleClose={() => setShowBlockTimeModal(false)} reloadSignal={reRender} />

        <BookingDetailsModal show={showBookingModal} handleClose={() => setShowBookingModal(false)} bookingId={bookingId} reloadSignal={reRender} />
      </Page>
    )
}