import { useState, useEffect } from "react";
import {
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StorageKeys } from "../../../services/constants/StorageKeys";
import { storage } from "../../../services/storageService";
import Page from '../../../components/Page';
import useConfirmBooking from "../api/useConfirmBooking";
import Article from "../../../components/Article";
import CustomButton from "../../../components/CustomButton";
import SelectedSupply from "../../../components/SelectedSupply";
import BorderedBox from "../../../components/BorderedBox";
import Loading from "../../../components/Loading";
import { Routes } from "../../../routes/RouteConstants";

const ConfirmBookingLayout = () => {
  const { loading, confirmBooking } = useConfirmBooking();
  const navigate = useNavigate();

  const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
  const selectedAppointment = storage.get(StorageKeys.SELECTED_APPOINTMENT);
  
  const handleBookingConfirmation = async () => {
    const preReservedBookingId =  storage.get(StorageKeys.PRE_RESERVED_BOOKING_ID);
    
    const response = await confirmBooking(preReservedBookingId);
    if (!response) return;
    
    storage.remove(StorageKeys.SELECTED_SUPPLY);
    storage.remove(StorageKeys.SELECTED_APPOINTMENT);
    storage.remove(StorageKeys.PRE_RESERVED_BOOKING_ID);
    navigate(Routes.SUCCESSFUL_BOOKING);
  };

  return (
    <Page>
      <Article title="Foglalás véglegesítése" titleVariant="h5" minWidth="common">
        {loading && <Loading />}

        {!loading && 
          <>
            <Stack spacing={3} minWidth="100%" mt={2} mb={5}>
              <SelectedSupply title="Szolgáltatás" supply={selectedSupply} />
              
              <BorderedBox title = "Időpont">
                <Typography fontWeight="bold">{selectedAppointment.date.replaceAll("-", ".")}</Typography>
                <Typography>{selectedAppointment.weekday}</Typography>
                <Typography color="text.primary">{selectedAppointment.time}</Typography>
              </BorderedBox>
            </Stack>

            <CustomButton text="Véglegesítés" onClick={handleBookingConfirmation}/>
          </>
        }
      </Article>
    </Page> 
  );
};

export default ConfirmBookingLayout;
