import React from 'react';
import { Container, Box } from '@mui/material';

const Page = ({ children }) => {
  return (
    <Container component="main" maxWidth="xl">
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ my: { xs: 3, md: 5 } }}>
        {children}
      </Box>
    </Container>
  );
};

export default Page;
