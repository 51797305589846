import { TextField, Stack } from '@mui/material';
import {useState } from 'react';
import CustomModal from './CustomModal';
import CustomButton from '../../../components/CustomButton';
import useManualBooking from '../api/useManualBooking';
import { useSnackbar } from '../../../contexts/SnackBarContext';

const ManualBookingModal = (props) => {
  const { show, handleClose, modalData, reloadSignal = () => null } = props;
  const { date = null, time = null, supply = null } = modalData || {};
  const { manualBooking } = useManualBooking();
  const [guest, setGuest] = useState("");
  const showSnackbar = useSnackbar();

  const handleManualBooking = async () => {
    if (!guest || guest === "" || !supply || !supply.id || !date || !time) {
      return;
    }

    const success = await manualBooking({ supplyId: supply.id, date: date, startTime: time.startTime, guest: guest });

    if (success) {
      showSnackbar("Sikeres foglalás", "success");
      reloadSignal();
      handleClose();
    }
  };

  return (
    <CustomModal
      open={show}
      onClose={handleClose}
      title="Foglalás">
      <Stack spacing={3} alignItems="center">
        <TextField 
          id="appointment-modal-name-textfield"
          label="Név" 
          variant="outlined" 
          margin="normal"
          fullWidth={true} 
          value={guest} 
          onChange={(event) => setGuest(event.target.value)} 
        />

        <CustomButton
          text="Foglalás" 
          onClick={handleManualBooking} 
        />
      </Stack>
    </CustomModal>
  );
};

export default ManualBookingModal;
