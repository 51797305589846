import { Paper, Typography } from "@mui/material";

const Article = ({
  width, 
  minWidth,
  maxWidth,
  xsHorizontalPadding = 5,
  title,
  titleColor = "secondary",
  titleVariant = "h4",
  alignItems = "center",
  children,
}) => {

  return (
    <Paper 
      elevation={3}
      component="article"
      sx={{ 
        width: width,
        minWidth: minWidth === "common" ? { xs: "70vw", sm: "60vw", md: "50vw", lg: "40vw", xl: "30vw" } : minWidth,
        maxWidth: maxWidth,
        borderRadius: 4, 
        py: 5,
        px: { xs: xsHorizontalPadding, sm: 5 }, 
        display: "flex", 
        flexDirection: "column", 
        alignItems: alignItems, 
      }}>
        {title && 
          <Typography 
            align="center"
            variant={titleVariant}
            fontWeight="bold"
            noWrap 
            mb={4}
            color={titleColor} 
          > 
            {title} 
          </Typography>
        }

        {children}   
    </Paper>
  )
}

export default Article
