import { useState } from 'react';
import apiClient from '../../services/apiClientService';
import useErrorHandler from '../../hooks/useErrorHandler';
import { useTenant } from '../../contexts/TenantContext';

const useCompleteRegistration = () => {
  const { getTenant } = useTenant();
  const [loading, setLoading] = useState(false);
  const { handleError } = useErrorHandler();
  
  const completeRegistration = async (requestData) => {
    setLoading(true);

    try {
      const tenant = await getTenant();
      const url = `/v1/tenants/${tenant?.tenantId}/auth/complete-registration`
      const response = await apiClient.put(url, requestData);
      const redirectUrl = response?.data?.redirectUrl;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        handleError({ message: "Sajnos nem sikerült a véglegesíteni regisztrációt. Foglalj időpontot telefonon keresztül. 06 30 452 8948" });
      }
    } catch (error) {
      handleError(error)
    } finally {
      setLoading(false);
    }
  };

  return { loading, completeRegistration };
};

export default useCompleteRegistration;
