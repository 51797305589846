import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Grid,
  Card,
  IconButton,
  Drawer,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { StorageKeys } from "../../../services/constants/StorageKeys";
import { storage } from "../../../services/storageService";
import SelectedSupply from '../../../components/SelectedSupply';
import Page from '../../../components/Page';
import Loading from '../../../components/Loading';
import usePagination from '../hooks/usePagination';
import CustomButton from '../../../components/CustomButton';
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/RouteConstants";
import Article from '../../../components/Article';
import useGetDailySchedules from '../../../api/useGetDailySchedules';
import dayjs from 'dayjs';
 
const BookingLayout = () => {
  const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
  const { data: newAppointments, loading, getDailySchedules  } = useGetDailySchedules(false, selectedSupply.id);
  const [loadedAppointments, setLoadedAppointments] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const selectedAppointment = storage.get(StorageKeys.SELECTED_APPOINTMENT);

  const fetchAppointments = () => {
    let newDate = null;
    if (newAppointments.length > 0) {
      const lastAppointmentDay = newAppointments[newAppointments.length - 1];
      newDate = dayjs(lastAppointmentDay.date).add(1, 'day');
    } 
    getDailySchedules(newDate, false, selectedSupply.id);
  };

  const {
    currentPage,
    paginatedItems,
    handlePrevPage,
    handleNextPage
  } = usePagination(loadedAppointments, fetchAppointments);

  
  useEffect(() => {
    if (newAppointments && newAppointments.length > 0) {
      setLoadedAppointments(loadedAppointments => [...loadedAppointments, ...newAppointments]);
    }
  }, [newAppointments]);

  const handleAppointmentClick = (date, weekday, time) => {
    storage.set(StorageKeys.SELECTED_APPOINTMENT, { date, weekday, time });
    setDrawerOpen(true);
  };

  const handleCancelAppointment = () => {
    storage.remove(StorageKeys.SELECTED_APPOINTMENT);
    setDrawerOpen(false);
  }

  const renderDays = () => {
    return paginatedItems.map(dailySchedule => {
      const date = dailySchedule.date;
      return (
        <Grid key={date} item xs={4} sm={3} md={3} lg={2}>
          <Card>
            <Stack direction={{ xs: "column", xl: "row" }} justifyContent="space-around" alignItems="center" p={1} bgcolor="black">
              <Typography variant="h6" textTransform="capitalize" color="white" noWrap>
                {dailySchedule.weekday}
              </Typography>
              <Typography variant="body1" color="grey.200" noWrap fontSize={{ xs: 12, sm: 14, md: 16 }}>
                {dailySchedule.formattedDate}
              </Typography>
            </Stack>
            <Box sx={{ height: "50vh", overflowY: "auto" }}>
              <Stack p={1} spacing={1}>
                {dailySchedule.timeSlots.map(timeSlot => (
                  <Button
                    key={`${date}-${timeSlot?.startTime}`}
                    variant={
                      selectedAppointment && selectedAppointment.date === date && selectedAppointment.time === timeSlot?.startTime ?
                        "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => handleAppointmentClick(date, dailySchedule.weekday, timeSlot?.startTime)}
                  >
                    {timeSlot?.startTime}
                  </Button>
                ))}
              </Stack>
            </Box>
          </Card>
        </Grid>
      );
    });
  };

  return (
    <Page>
      <Article xsHorizontalPadding={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={{ xs: 3, md: 5}}>
          <Typography variant="h5" fontWeight="bold" color="black">A választott szolgáltatás</Typography>
          <SelectedSupply fixedWidth="130px" supply={selectedSupply}/>
        </Stack>
      </Article>

      {loading && <Loading />}

      {!loading && loadedAppointments.length > 0 && (
        <>
          <Box my={1} display="flex" justifyContent="center" alignItems="center">
            <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton onClick={handleNextPage}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            {renderDays()}
          </Grid>
        </>
      )}

      <Drawer anchor="bottom" open={drawerOpen} onClose={handleCancelAppointment}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={3} p={3} justifyContent={{ sm: "space-evenly"}} alignItems="center"> 
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="center"> 
            <SelectedSupply fixedWidth="200px" supply={selectedSupply} />

            {selectedAppointment && 
              <Stack>
                <Typography fontWeight="bold">{selectedAppointment.date.replaceAll("-", ".")}</Typography>
                <Typography >{selectedAppointment.weekday}</Typography>
                <Typography>{selectedAppointment.time}</Typography>
              </Stack>
            }
          </Stack>
         
          <CustomButton text="Tovább" onClick={() => navigate(Routes.PRE_RESERVE)}/>
        </Stack>
      </Drawer>
    </Page>
  );
};

export default BookingLayout;
