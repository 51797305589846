import { Typography } from "@mui/material";
import Page from "../components/Page";
import Article from "../components/Article";

const ErrorLayout = () => {
  return (
    <Page>
      <Article title="Hiba történt!" titleColor="red" minWidth="common">
        <Typography variant="body2" color="red">Próbáld újra később</Typography>
      </Article>
    </Page>
  )
}

export default ErrorLayout;
