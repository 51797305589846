import { useState } from 'react';
import apiClient from '../../../services/apiClientService';
import { Routes } from '../../../routes/RouteConstants';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { useTenant } from '../../../contexts/TenantContext';

const useGetBooking = () => {
  const { getTenant } = useTenant();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { handleError } = useErrorHandler();

  const getBooking = async (bookingId) => {
    setLoading(true);

    try {
      const tenant = await getTenant();
      const url = `/v1/tenants/${tenant.tenantId}/bookings/${bookingId}`;
      const response = await apiClient.get(url);
      setData(response.data);
    } catch (error) {
      handleError(error, Routes.DASHBOARD);
    } finally {
      setLoading(false);
    }
  };

  return { getBooking, data, loading };
};

export default useGetBooking;
